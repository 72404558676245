import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { articles } from "./articles.module";
import { accounts } from "./accounts.module";
import { offices } from "./offices.module";
import { sectors } from "./sectors.module";
import { stations } from "./stations.module";
import { charts } from "./charts.module";
import { alarms } from "./alarms.module";
import { trackings } from "./trackings.module";
import { logs } from "./logs.module"
import { knowledges } from "./knowledges.module";
import { categories } from "./categories.module"
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    accounts,
    categories,
    offices,
    sectors,
    stations,
    charts,
    alarms,
    trackings,
    logs,
    knowledges,
    articles
  },
});
