import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "articles/";
import authHeader from "../services/auth-header";
export const articles = {
  state: {
    articles: [],
  },
  getters: {
    allArticles: (state) => state.articles,
    oneArticle: (state) => state.article
  },
  mutations: {
    setArticles(state, category) {
      state.articles = category;
    },
    setArticle(state, article) {
      state.article = article;
    },
    addArticles(state, category) {
      let articles = state.articles;
      articles.push(category);
      state.articles = articles;
    },
    editArticles(state, category) {
      let articles = state.articles;

      let index = articles.findIndex((element) => element._id === category._id);
      if (index != -1) {
        articles[index].name = category.name;
        state.articles = articles;
      }
    },
    deleteArticles(state, id) {
      let articles = state.articles;

      let filterArticles = articles.filter((element) => element._id !== id);

      state.articles = filterArticles;
    },
  },
  actions: {
    async getArticles(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          context.commit("setArticles", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    async getArticle(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("setArticle", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.res;
        });
    },
    createArticles(context, data) {
      return axios
        .post(apiURL, data, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          context.commit("addArticles", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    deleteArticles(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          context.commit("deleteArticles", id);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    editArticles(context, data) {
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          context.commit("editArticles", data);
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error.response;
        });
    },
  },
};
