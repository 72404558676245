import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "offices/";
import authHeader from "../services/auth-header";
export const offices = {
  state: {
    offices: [],
  },
  getters: {
    allOffices: (state) => state.offices,
  },
  mutations: {
    setOffice(state, office) {
      state.offices = office;
    },
    addOffice(state, office) {
      let offices = state.offices;
      offices.push(office);
      state.offices = offices;
    },
    editOffice(state, office) {
      let offices = state.offices;

      let index = offices.findIndex((element) => element._id === office._id);
      if (index != -1) {
        offices[index].name = office.name;
        state.offices = offices;
      }
    },
    deleteOffice(state, id) {
      let offices = state.offices;

      let filterOffice = offices.filter((element) => element._id !== id);

      state.offices = filterOffice;
    },
  },
  actions: {
    async getOffices(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res)
          context.commit("setOffice", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
    createOffices(context, data) {
      return axios
        .post(
          apiURL,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          context.commit("addOffice", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    deleteOffices(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          context.commit("deleteOffice", id);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    editOffices(context, data) {
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          context.commit("editOffice", data);
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error;
        });
    },
  },
};
