import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "logs/";
import authHeader from "../services/auth-header";
//import authHeader from "../services/auth-header";
export const logs = {
  state: {
    logs: [],
  },
  getters: {
    getLogs: (state) => state.logs,
  },
  mutations: {
    setLog(state, logs) {
      state.logs = logs;
    },
  },
  actions: {
    async searchLog(context, data) {
      console.log(data);
      return axios
        .post(apiURL + "search",data,{ headers: authHeader() } )
        .then((res) => {
          console.log(res);
          context.commit("setLog", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    },
  },
};
