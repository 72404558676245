//import getStorage from "../services/auth-expire";


/* export default function authHeader() {
  let user = JSON.parse(Cookies.get("user"));
  console.log(user)
  if (user && user.access_token) {
    return { Authorization: "Bearer " + user.access_token };
  } else {
    return {};
  }
} */

import getStorage from "../services/auth-expire";
export default function authHeader() {
  let user = getStorage("user");
  console.log(user);
  if (user && user.access_token) {
    return { Authorization: "Bearer " + user.access_token };
  } else {
    return {};
  }
}

// import getStorage from "../services/auth-expire";
// export default function authHeader() {
//   let user = getStorage("user");
//   if (user && user.accessToken) {
//     return { Authorization: "Bearer " + user.access_token };
//   } else {
//     return {

//     };
//   }
// }
