import ChartsService from "../services/chart.service";
export const charts = {
  state: {
    chartsOil: ChartsService.chartOil(),
    chartsFlow: ChartsService.chartFlow(),
  },
  getters: {
    getChartOil: (state) => state.chartsOil,
    getChartFlow: (state) => state.chartsFlow,
  },
  mutations: {
    setChart(state, charts) {
      state.chartsOil = charts.chartsOil;
      state.chartsFlow = charts.chartsFlow;
    },
  },
  actions: {
    searchCharts({ commit }, data) {
      console.log(data);
      return ChartsService.searchCharts(data).then(
        (charts) => {
          console.log(charts);
          commit("setChart", charts);
          return charts;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
