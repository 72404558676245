import axios from "axios";
import authHeader from "../services/auth-header";
import Cookies from "js-cookie";
// import jwt_decode from "jwt-decode";
import VueJwtDecode from "vue-jwt-decode";

const apiURL = process.env.VUE_APP_API_URL + "auth/";
const URL = process.env.VUE_APP_API_URL;
class AuthService {
  login(user) {
    return axios
      .post(apiURL + "login", {
        username: user.username,
        password: user.password,
      })
      .then((res) => {
        if (res.data.access_token) {
          console.log(res)
          Cookies.set("user", JSON.stringify(res.data));
          return res.data;
        }
      });
  }
  logout() {
    Cookies.remove("user");
    Cookies.remove("nameBuliding");
    Cookies.remove("idBuliding");
  }
  resetPassword(payload) {
    return axios.put(
      URL + "users/resetPassword/" + payload.id,
      {
        newPassword: payload.newPassword,
      },
      { headers: authHeader() }
    );
  }
  changePassword(payload) {
    return axios.put(
      apiURL + "changePassword/" + payload.id,
      {
        newPassword: payload.newPassword,
        password: payload.password,
      },
      { headers: authHeader() }
    );
  }
  jwtDecode() {
    const user = JSON.parse(Cookies.get("user"));
    console.log(user);
    console.log(user.access_token);
    console.log("user");
    return VueJwtDecode.decode(user.access_token);
  }
}
export default new AuthService();
