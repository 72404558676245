import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "sectors/";
import authHeader from "../services/auth-header";
export const sectors = {
  state: {
    sectors: [],
  },
  getters: {
    allSectors: (state) => state.sectors,
  },
  mutations: {
    setSector(state, sector) {
      state.sectors = sector;
    },
    addSector(state, sector) {
      let sectors = state.sectors
      sectors.push(sector);
      state.sectors = sectors;
    },
    editSector(state, sector) {
      let sectors = state.sectors;

      let index = sectors.findIndex((element) => element._id === sector._id);
      if (index != -1) {
        sectors[index].name = sector.name;
        state.sectors = sectors;
      }
    },
    deleteSector(state, id) {
      let sectors = state.sectors;

      let filterSector = sectors.filter((element) => element._id !== id);

      state.sectors = filterSector;
    },
  },
  actions: {
    async getSectors(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          context.commit("setSector", res.data);
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    createSectors(context, data) {
      return axios
        .post(
          apiURL,
          {
            name: data.name,
            office: data.sector
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          context.commit("addSector", res.data);
          return res
        })
        .catch((error) => {
          console.log(error.response);
          return error.response
        });
    },
    deleteSectors(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          context.commit("deleteSector", id);
          return res
        })
        .catch((error) => {
          console.log(error.response);
          return error.response
        });
    },
    editSectors(context, data) {
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          context.commit("editSector", data);
          return res
        })
        .catch((error) => {
          //console.log(error)
          return error.response
        });
    }
  },
};
