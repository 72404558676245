import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "stations/";
import authHeader from "../services/auth-header";
export const stations = {
  state: {
    station: null,
    stations: [],
  },
  getters: {
    oneStation: (state) => state.station,
    allStations: (state) => state.stations,
  },
  mutations: {
    SOCKET_statusOnline(state, data) {
      if (state.station != null) {
        if (state.station._id == data._id) {
          state.station.statusOnline = data.statusOnline;
        }
      }
      if (state.stations.length != 0) {
        let index = state.stations.findIndex((s) => s._id === data._id);
        if (index > -1) {
          state.stations[index].statusOnline = data.statusOnline;
        }
      }
    },
    SOCKET_status(state, data) {
      if (state.station != null) {
        if (state.station._id == data.station) {
          if (data.type == "StatusEngine") {
            const index = state.station.status.findIndex(
              (s) => s.name === data.name
            );
            if (index > -1) {
              //console.log(index);
              state.station.status[index].value = data.value;
            }
          }
          if (data.name == "GPS") {
            state.station.GPS.lat = data.lat;
            state.station.GPS.lng = data.lng;
          }
        }
      }
    },
    setStations(state, stations) {
      state.stations = stations;
    },
    setStation(state, station) {
      state.station = station;
    },
    addStation(state, station) {
      let stations = state.stations;
      stations.push(station);
      state.stations = stations;
    },
    editStation(state, station) {
      let stations = state.stations;

      let index = stations.findIndex((element) => element._id === station._id);
      if (index != -1) {
        stations[index].name = station.name;
        state.stations = stations;
      }
    },
    deleteStation(state, id) {
      let stations = state.stations;

      let filterStation = stations.filter((element) => element._id !== id);

      state.stations = filterStation;
    },
  },
  actions: {
    async getStations(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          context.commit("setStations", res.data);
          return res
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createStations(context, data) {
      return axios
        .post(apiURL, data, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          context.commit("addStation", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error.response);
          return error.response;
        });
    },
    deleteStations(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          context.commit("deleteStation", id);
          return res
        })
        .catch((error) => {
          console.log(error.response);
          return error.response
        });
    },
    editStations(context, data) {
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          context.commit("editStation", data);
          return res;
        })
        .catch((error) => {
          //console.log(error)
          return error.response;
        });
    },
    async getStation(context, id) {
      console.log(id);
      //console.log(test);
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          context.commit("setStation", res.data);
          return res;
        })
        .catch((error) => {
          console.log(error);
          return error.res
        });
    },
    async setManual(context, id) {
      return axios
        .put(apiURL + "manual/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    async setStart(context, id) {
      return axios
        .put(apiURL + "start/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    async setStop(context, id) {
      return axios
        .put(apiURL + "stop/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    async setOnPump1(context, id) {
      return axios
        .put(apiURL + "onpump1/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    async setOnPump2(context, id) {
      return axios
        .put(apiURL + "onpump2/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    async setOnPump3(context, id) {
      return axios
        .put(apiURL + "onpump3/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    async setOffPump(context, id) {
      return axios
        .put(apiURL + "offpump/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
  },
};
