<template>
  <v-layout v-resize="onResize" column>
    <div>
      <template v-if="!$route.meta.allowAnonymous">
        
        <template v-if="isMobile">
          <v-app style="background: #f8f8fa">
            <Navbar />
            <v-main class="mb-15">
              <router-view></router-view>
            </v-main>
            <BottomNavigation />
          </v-app>
        </template>
        <template v-else>
          <v-app style="background: #f8f8fa">
            <Navbar />
            <v-main>
              <router-view></router-view>
            </v-main>
          </v-app>
        </template>
      </template>
      <template v-else>
        <transition>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </template>
    </div>
  </v-layout>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import BottomNavigation from "./components/BottomNavigation.vue";
export default {
  name: "App",

  components: {
    Navbar,
    BottomNavigation,
  },

  data() {
    return {
      isMobile: false,
      isApp: false,
    };
  },
  mounted() {
    console.log(process.env.VUE_APP_MAPS_API_KEY);
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
  },
  methods: {
    onResize() {
      if (window.innerWidth < 1000) this.isMobile = true;
      else this.isMobile = false;
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Kanit");
.v-application {
  font-family: "Kanit";
}
</style>
