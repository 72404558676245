import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "articles/";
import authHeader from "../services/auth-header";
export const knowledges = {
  state: {
    knowledges: [],
  },
  getters: {
    allKnowledges: (state) => state.knowledges,
  },
  mutations: {
    setKnowledge(state, knowledge) {
      state.knowledges = knowledge;
    },
    addKnowledge(state, knowledge) {
      let knowledges = state.knowledges
      knowledges.push(knowledge);
      state.knowledges = knowledges;
    },
    editKnowledge(state, knowledge) {
      let knowledges = state.knowledges;

      let index = knowledges.findIndex((element) => element._id === knowledge._id);
      if (index != -1) {
        knowledges[index].name = knowledge.name;
        state.knowledges = knowledges;
      }
    },
    deleteKnowledge(state, id) {
      let knowledges = state.knowledges;

      let filterKnowledge = knowledges.filter((element) => element._id !== id);

      state.knowledges = filterKnowledge;
    },
  },
  actions: {
    async getKnowledges(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          context.commit("setKnowledge", res.data);
          return res
        })
        .catch((error) => {
          console.log(error);
          return error.response
        });
    },
    createKnowledges(context, data) {
      return axios
        .post(
          apiURL,
          {
            name: data.name,
            office: data.knowledge
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          context.commit("addKnowledge", res.data);
          return res
        })
        .catch((error) => {
          console.log(error.response);
          return error.response
        });
    },
    deleteKnowledges(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          context.commit("deleteKnowledge", id);
          return res
        })
        .catch((error) => {
          console.log(error.response);
          return error.response
        });
    },
    editKnowledges(context, data) {
      return axios
        .put(
          apiURL + data._id,
          {
            name: data.name,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          context.commit("editKnowledge", data);
          return res
        })
        .catch((error) => {
          //console.log(error)
          return error.response
        });
    }
  },
};
