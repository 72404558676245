<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-card-title>
        <h3>{{ $t("Stations") }}</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          color="#2C3A47"
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="getStations"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="success"
            class="mx-2"
            fab
            x-small
            @click="editUser(item)"
            outlined
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="pink"
            fab
            x-small
            @click="deleteUser(item._id)"
            outlined
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:no-results>
          <v-alert :value="true" color="pink" icon="warning" dark>
            Your Search {{ search }} does not exist.
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      class="mx-2 my-10"
      fixed
      :style="isMobile ? 'bottom: 50px;' : 'bottom: -20px;'"
      fab
      color="info"
      dark
      absolute
      bottom
      right
      @click="
        close();
        opendialog();
      "
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title v-if="create" class="info white--text">
          {{ $t("Add ") }}{{ $t("Station") }}
        </v-card-title>
        <v-card-title v-else class="info white--text">
          {{ $t("Edit ") }}{{ $t("Station") }}
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="savePost"
            ref="stationData"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-text-field
              name="name"
              id="id"
              label="Name"
              hide-details="auto"
              v-model="stationData.name"
              :rules="[(v) => !!v || 'Name is required']"
              required
            ></v-text-field>

            <v-select
              :items="getOffices"
              item-text="name"
              item-value="id"
              label="Office"
              v-model="stationData.office"
              @change="changeOffice"
              :rules="[(v) => !!v || 'Office is required']"
              required
            ></v-select>
            <v-select
              :items="getSectors"
              item-text="name"
              item-value="id"
              label="Sector"
              v-model="stationData.sector"
              :rules="[(v) => !!v || 'Sector is required']"
              required
            ></v-select>
            <v-select
              :items="getType"
              item-text="name"
              item-value="id"
              label="Type"
              v-model="stationData.type"
              :rules="[(v) => !!v || 'Type is required']"
              required
            ></v-select>
            <v-text-field
              name="Gateway Serial"
              id="id"
              label="Gateway serial"
              hide-details="auto"
              v-model="stationData.gatewaySerial"
              :rules="[(v) => !!v || 'Gateway serial is required']"
              required
            >
            </v-text-field>
            <v-text-field
              name="Equipment number"
              id="id"
              label="Equipment number"
              hide-details="auto"
              v-model="stationData.equipmentNumber"
              :rules="[(v) => !!v || 'Equipment number is required']"
              required
            >
            </v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="success" text @click="savePost()">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" buttom left :color="color">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
//import axios from "axios";
import {
  isIOS,
  isMobile,
  isAndroid,
  isSafari,
  isWinPhone,
} from "mobile-device-detect";
export default {
  name: "AccountPage",
  components: {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getStations() {
      return this.$store.getters.allStations;
    },
    getSectors() {
      return this.$store.getters.allSectors;
    },
    getOffices() {
      return this.$store.getters.allOffices;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    //console.log(this.getUsers);
    this.loadStations();
  },
  data() {
    return {
      isMobile: isIOS || isSafari || isAndroid || isMobile || isWinPhone,
      changePassword: false,
      create: true,
      mini: false,
      dialog: false,
      dialogReset: false,
      fab: false,
      hidePassword: true,
      hideConfirmPassword: true,
      hideNewPassword: true,
      search: "",
      snackbar: false,
      text: "",
      color: "",
      btnName: "",
      users: [],
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Action", value: "actions", sortable: false, align: "center" },
      ],
      stationData: {
        name: "",
        office: "",
        sector: "",
        type: "",
        gatewaySerial: "",
        equipmentNumber: "",
        model: "",
        serial: "",
        engineSerial: "",
        hydraulicPumpSerial: "",
        hydraulicMotorSerial: "",
      },
      default: {
        name: "",
        office: "",
        sector: "",
        type: "",
        gatewaySerial: "",
        equipmentNumber: "",
        model: "",
        serial: "",
        engineSerial: "",
        hydraulicPumpSerial: "",
        hydraulicMotorSerial: "",
      },
      editedIndex: -1,
      payload: {
        id: "",
        newPassword: "",
        active: true,
      },
    };
  },
  methods: {
    opendialog() {
      this.dialog = true;
      this.create = true;
      this.changePassword = false;
      this.selectAdmin = false;
    },
    loadStations() {
      this.$store.dispatch("getStations");
      this.$store.dispatch("getOffices");
      this.$store.dispatch("getSectors");
      //this.$store.dispatch("getCompanies");
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.stationData = Object.assign({}, this.default);
        this.editedIndex = -1;
        this.changePassword = false;
        this.create = true;
        this.$refs.stationData.resetValidation();
        this.departmetEnable = false;
      }, 300);
    },
    savePost() {
      if (this.editedIndex > -1) {
        this.updateUser();
      } else {
        this.createUser();
      }
    },
    createUser() {
      if (this.$refs.stationData.validate()) {
        this.$store.dispatch("auth/register", this.stationData).then(
          () => {
            this.dialog = false;
            this.loadUsers();
            this.color = "success";
            this.text = "User has been successfully saved.";
            this.snackbar = true;
          },
          (error) => {
            this.color = "error";
            this.text = error.response.data.error.message;
            this.snackbar = true;
          }
        );
      }
    },
    editUser(item) {
      if (item.company != "") {
        this.$store.dispatch("getCompanyWithDepartment", item.company);
        this.departmetEnable = true;
      }
      this.editedIndex = this.getUsers.indexOf(item);
      this.stationData = Object.assign({}, item);
      this.create = false;
      this.dialog = true;

      this.changeRole(this.stationData.role);
    },
    updateUser() {
      if (
        this.stationData.password == undefined ||
        this.stationData.newPassword == undefined
      ) {
        this.stationData.password = "";
        this.stationData.newPassword = "";
      }

      this.$store.dispatch("updateUser", this.stationData).then(
        () => {
          this.close();

          this.color = "info";
          this.text = "User has been modified.";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    deleteUser(id) {
      //console.log(id);
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            this.$store.dispatch("deleteUser", id);
          } catch (error) {
            console.log(error);
          }
          this.$swal("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    resetPassword(id) {
      this.password.id = id;
      this.dialogReset = true;
    },
    resetPasswordConfirm() {
      if (this.password.new == this.password.confirm) {
        this.payload.id = this.password.id;
        this.payload.newPassword = this.password.new;
        this.$store.dispatch("auth/resetPassword", this.payload).then(
          () => {
            this.closeModalReset;
            this.color = "info";
            this.text = "Reset Password Complete.";
            this.snackbar = true;
          },
          (error) => {
            if (error.response != undefined) {
              this.text = error.response.data.error.message;
            } else {
              this.text = error.message;
            }
            this.color = "red";
            this.snackbar = true;
          }
        );
      } else {
        this.color = "red";
        this.text = "New Password and Confirm Password do not match";
        this.snackbar = true;

        return;
      }
    },
    closeModalReset() {
      this.dialogReset = false;
      setTimeout(() => {
        this.password.id = "";
        this.password.new = "";
        this.password.confirm = "";
        this.$refs.resetPass.resetValidation();
      }, 300);
    },
    checkboxUpdated(item) {
      console.log(item);
      this.payload.id = item._id;
      this.payload.active = item.active;
      this.$store.dispatch("auth/active", this.payload).then(
        () => {
          if (this.payload.active) {
            this.text = "Account Active Complete.";
          } else {
            this.text = "Account Not Active Complete.";
          }
          this.closeModalReset;
          this.color = "info";
          this.snackbar = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    changeCompany(id) {
      this.$store.dispatch("getCompanyWithDepartment", id).then(
        () => {
          this.departmetEnable = true;
        },
        (error) => {
          if (error.response != undefined) {
            this.text = error.response.data.error.message;
          } else {
            this.text = error.message;
          }
          this.color = "red";
          this.snackbar = true;
        }
      );
    },
    changeRole(item) {
      if (item == "admin") {
        this.selectAdmin = false;
      } else {
        this.selectAdmin = true;
      }
    },
  },
};
</script>
